import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let permissions = [
	{
		name: '列表',
		btn_type: 1,
	},
	{
		name: '查看',
		btn_type: 2,
	},
	{
		name: '新增',
		btn_type: 3,
	},
	{
		name: '编辑',
		btn_type: 4,
	},
	{
		name: '删除',
		btn_type: 5,
	},
	{
		name: '下载',
		btn_type: 6,
	},
	{
		name: '导入',
		btn_type: 7,
	},
	{
		name: '导出',
		btn_type: 8,
	},
	{
		name: '上传',
		btn_type: 9,
	},
	{
		name: '打印',
		btn_type: 10,
	},
	{
		name: '启/停用',
		btn_type: 11,
	},
	{
		name: '还原密码',
		btn_type: 12,
	},
	{
		name: '选择档案',
		btn_type: 13,
	},
	{
		name: '新建子类',
		btn_type: 14,
	},
	{
		name: '选择指标',
		btn_type: 15,
	},
	{
		name: '身体功能测试',
		btn_type: 16,
	},
	{
		name: '力量测试',
		btn_type: 17,
	},
	{
		name: '速度测试',
		btn_type: 18,
	},
	{
		name: '灵敏性测试',
		btn_type: 19,
	},
	{
		name: '爆发力测试',
		btn_type: 20,
	},
	{
		name: '耐力测试',
		btn_type: 21,
	},
	{
		name: '主观状态',
		btn_type: 22,
	},
	{
		name: '运动表现',
		btn_type: 23,
	},
	{
		name: '体重管理',
		btn_type: 24,
	},
	{
		name: '机能状态',
		btn_type: 25,
	},
	{
		name: '账号设置',
		btn_type: 26,
	},
	{
		name: '角色设置',
		btn_type: 27,
	},
	{
		name: '权限设置',
		btn_type: 28,
	},
	{
		name: '选择账号',
		btn_type: 29,
	},
	{
		name: '附件',
		btn_type: 30,
	},
	{
		name: '查看详情',
		btn_type: 31,
	},
]
export default new Vuex.Store({
	state: {
		trainIndex: '',
		trainDate: [new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30), new Date(new Date().getTime())],
		is: '',
		company: '',
		img_show: window.img_show,
		url: window.server,
		img_url: window.img_server,
		NODE_ENV: process.env.NODE_ENV === 'production' ? '/dist/' : '/',
		permissionsArr: [],
		permissionsName: [],
		permissionsData: [],
		equipment: (document.documentElement.clientWidth || window.innerWidth) > 1100,
		treeMenu: null, //二级菜单
		menu: [], //一级菜单
		department: sessionStorage.getItem('department') ? sessionStorage.getItem('department') : '',
		lastTreeMenu: null,
		breadcrumbArr: sessionStorage.getItem('breadcrumbArr') ? sessionStorage.getItem('breadcrumbArr').split(',') : [],
		basicInfoVar: null,
		getProjectTree: [], //项目列表树形
		staff_info: null, //人员信息
		userData: sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : null,

		defaultCheckedKeys: [], //穿梭框默认选中
		treeTransferData: [], //穿梭框数据
		treeTransferType: false, //穿梭框展示隐藏
		seleCheckDatas: [],
		dateBatch: false, // 日计划批量新建
		weekBatchs: false, // 周计划  批量新建
		monthBatch: false, // 月计划批量新建
		yearBatch: false, // 年计划批量新建
		tableMaxHeight: 500,
		tabType: 0,
		/*****************************/
		// orderList: [],
		// menuList: []
	},
	mutations: {
		setPermissionsArr(state, arr) {
			Vue.set(state, 'permissionsArr', arr)
		},
		/*    传入name 查找权限 permissionsName  */
		setPermissionsName(state, arr) {
			Vue.set(state, 'permissionsName', arr)
		},
		setTabType(state, type) {
			state.tabType = type
		},
		setPermissionsData(state, arr) {
			Vue.set(state, 'permissionsData', arr)
		},
		/********************************/
		// orderList(s, d) {
		//     s.orderList= d;
		//     window.localStorage.setItem("list",JSON.stringify(s.orderList))
		//   },
		//   menuList(s, d) {
		//     s.menuList = d;
		//     window.localStorage.setItem("list",JSON.stringify(s.menuList))
		//  },
	},
	actions: {},
	modules: {},
	getters: {
		/*
                    '1'=>'列表','2'=>'查看','3'=>'新增','4'=>'编辑','5'=>'删除',
                    '6'=>'下载','7'=>'导入','8'=>'导出','9'=>'上传','10'=>'打印',
                    '11'=>'启/停用','12'=>'还原密码','13'=>'选择档案','14'=>'新建子类', '15选择指标'
                    用法：
                        $store.getters.permissionsStr('列表') 传入汉字转化查询是否有权限
                        $store.getters.permissionsArr(1)   传入数字，直接查询，传入的是Number类型
                    <el-button :disabled="$store.getters.permissionsStr('新增')">
                        <span>新增</span>
                    </el-button>
                    <el-button :disabled="tableDataSelectArr.length == 0 || $store.getters.permissionsStr('删除')"  :class="$store.getters.permissionsStr('删除') ? 'disabledRed' : 'noDisabledRed'">
                        <i class="iconfont icon-shanchu-icon iconFontSize redColor"></i>
                        <span>删除</span>
                    </el-button>
                    <el-button
                        size="medium"
                        type="text"
                        class='redColor'
                        v-show="!$store.getters.permissionsStr('删除')"
                        @click="delTableData(scope.row)"
                        >删除
                    </el-button>


                */
		permissionsStr: state => type => {
			let num = -1
			permissions.forEach((i, k) => {
				if (i.name == type) {
					num = Number(i.btn_type)
				}
			})
			return state.permissionsArr.indexOf(num) == -1
		},
		permissionsArr: state => type => {
			// return false;
			return state.permissionsArr.indexOf(type) == -1
		},
		permissionsName: state => type => {
			// return false;
			return state.permissionsName.indexOf(type) == -1
		},
		permissionsData: state => type => {
			return state.permissionsData.filter(item => {
				if (item.link == type) return item
			})
		},
	},
})
